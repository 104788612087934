import { SET_USER } from './userTypes'

const fetchSession = (session) => {
    return {
        type: SET_USER,
        payload: session
    }
}


export {
    fetchSession
}