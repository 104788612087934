import {
    SET_CART,
    SET_COMBOS,
    SET_NUMB_OF_ITEMS,
    SET_TOTAL,
    SET_USER_DATA
} from './storeTypes'

const fetchCart = (cart) => {
    return {
        type: SET_CART,
        payload: cart
    }
}

const fetchCombos = (combos) => {
    return {
        type: SET_COMBOS,
        payload: combos
    }
}

const fetchTotal = (total) => {
    return {
        type: SET_TOTAL,
        payload: total
    }
}

const fetchUserData = (userData) => {
    return {
        type: SET_USER_DATA,
        payload: userData
    }
}


const fetchNumbsOfItems = (numbsOfItems) => {
    return {
        type: SET_NUMB_OF_ITEMS,
        payload: numbsOfItems
    }
}

export {
    fetchCart,
    fetchCombos,
    fetchTotal,
    fetchUserData,
    fetchNumbsOfItems
}