import React from 'react'
import { FooterContainer } from './Footer/Styles/Styles'
import Logo from './../../src/Assets/cropped-logosocial-300x300-min.png'


import {
    WhatsAppOutlined,
    PhoneOutlined,
    MailOutlined,
    EnvironmentOutlined
} from '@ant-design/icons'

export default function Footer() {
    return (
        <FooterContainer>

            <div className="flex Footer-section1">
                <div className="LogoFooter-container flex">

                    <img src={Logo} alt="Imagen no disponible" />

                </div>

                <div className="Footer-section1-row">
                    <h3>Puntos de venta</h3>
                    <ul>
                        <li><EnvironmentOutlined className="itemLogo"/> Cra 40 #50B 05 Placita de Flórez</li>
                        <li><EnvironmentOutlined className="itemLogo"/> Cra 51 50A 64 Copacabana</li>
                    </ul>
                </div>

                <div className="Footer-section1-row">

                    <h3>Contactenos</h3>

                    <ul>
                        <li><WhatsAppOutlined className="itemLogo" /> Whastapp: <a href="https://api.whatsapp.com/send?phone=5742041077&text=Hola!">+5742041077</a></li>
                        <li><PhoneOutlined className="itemLogo" /> Tel: <a href="tel:3184610647">3184610647</a></li>
                        <li><MailOutlined className="itemLogo" /> Email: <a href="email:contacto@santascarnitas.com">contacto@santascarnitas.com</a></li>
                    </ul>

                </div>
            </div>

            <div className="Footer-copyright flex">
                <p>@2020 SANTAS CARNITAS - Todos los derechos reservados. | Power by w2t</p>
            </div>


        </FooterContainer>
    )
}
