const { default: styled } = require("styled-components");
const backgroundFooterColor = "#b9180d";

const FooterContainer = styled.div`
    background-color: #b9180d;
    border-top: 1px solid #7070702b;
    height: 46vh;
    bottom: 0px;
    width: 100%;
    margin-top: 0vh;
    padding: 0px 80px;
    padding-top: 30px;
    box-shadow: -2px 0px 4px #0000001b;
    position: relative;
    img{
        width: 150px;
    }
    @media(max-width:600px){
        padding: 0px;
    }
    .Footer-section1{
        justify-content: space-between;
        align-items: flex-start;
        z-index: 1000;
        width: 80%;
        margin: auto;
        @media(max-width: 600px){
            flex-direction: column;
            width: 100%;
        }
    }
    .Footer-section1-row{
        margin-left: 50px;
        padding-top: 30px;
        color: white;
        h3{
            color: white;
            font-size: 20px;
        }
        ul{
            padding:0px;
        }
        li{
            list-style: none;
            font-weight: 400;
            margin-top: 8px;
        }
        a{
            margin-left: 5px;
            font-size: 16px;
            color: white;
            font-weight: 500;
            border-bottom: 1px solid #ffffff7b;
        }
        .itemLogo{
            margin-right: 10px;
            font-size: 18px;
        }
    }
    .Footer-copyright{
        color: white;
        font-size: 14px;
        width: 60%;
        margin: auto;
        margin-top: 7vh;
        border-top: 2px solid white;
        padding-top: 10px;
    }
    .LogoFooter-container{
        align-items: flex-start;
        height: 25vh;
    }
    @media (max-width: 600px){
        flex-direction: column;
        height: auto;
        align-items: flex-start;
        justify-content: start;
        bottom: 0px;
        padding-bottom: 80px;
        .Footer-section{
            margin-left: 0px;
            padding-top: 10px;
        }
        .LogoFooter-container{
            align-items: center;
            justify-content: center;
            width: 100%;
        }
    }
`


export { FooterContainer }