const { default: styled } = require("styled-components")


const HeaderContainer = styled.header`
    position: fixed;
    width: 100vw;
    z-index: 1000000000;
`

export {
    HeaderContainer
}