const SET_CART = 'SET_CART'
const SET_COMBOS = 'SET_COMBOS'
const SET_TOTAL = 'SET_TOTAL'
const SET_USER_DATA = 'SET_USER_DATA'
const SET_NUMB_OF_ITEMS = 'SET_NUMB_OF_ITEMS'




export {
    SET_CART,
    SET_COMBOS,
    SET_TOTAL,
    SET_USER_DATA,
    SET_NUMB_OF_ITEMS
}