import React from 'react'
import { Suspense } from 'react'

import Logo from './../../src/Assets/Logo Santas Carnitas.svg'


import {
    HeaderContainer
} from './Header/Styles/StyleHeader'
import { Link } from 'react-router-dom'
import DispositiveCheck from '../Util/deviceTrigger'

const MenuPc = React.lazy(()=> import('./Menu'))
const MenuMobile = React.lazy(()=> import('./MenuMobile'))

export default function Header({ showCatalogue }) {

    return (
        <HeaderContainer>

            <header className="Form-header">

                <div className="Form-header-menu">
                    <Link to="/tienda/"> <img src={Logo} alt="Imagen no disponible" /></Link>
                </div>

                <Suspense fallback={<div></div>}>

                {
                    DispositiveCheck () === "PC" ? <MenuPc /> : <MenuMobile />
                }

                </Suspense>


            </header>

        </HeaderContainer>
    )
}
