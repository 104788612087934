import React from 'react'

import {
    ShoppingCartOutlined
} from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { fetchCart, fetchCombos } from '../Redux/store/storeActions'
import { notification } from 'antd'

export default function PrintCombos({ sectionsCollapseStatus, setSectionsCollapseStatus, combosToPrint, changeQuantityItem, SubTotals, setCombos, Cart, setCart, openNotification }) {

    const dispatch = useDispatch()


    const getMessageNotification = (title, quantity, price) => {
        return "Cliente fiel - " + title + " x " + quantity + " | " + Number(price * quantity).toLocaleString("de-AT", { style: "currency", currency: "USD" })
    }

    async function addComboToCart(e, indexOfCombo, comboID) {

        e.persist()

        dispatch(fetchCart({combos: [combosToPrint[indexOfCombo]]}))

        setCart(prevState => {return { ...prevState, combos: [...prevState.combos, combosToPrint[indexOfCombo]] } })


        notification.success({
            message: "KIT Tarde de amigos",
            description: "Añadido al Carrito!",
            onClick: () => {
              console.log('Notification Clicked!');
            },
            placement: "bottomRight",
            className: "notifications"
          });

    }

    // async function changeMeetCutCombos(e, indexOfCombo, indexOfItem) {

    //     let TypeOfCut = e.target.value;

    //     combosToPrint[indexOfCombo].content[indexOfItem].TypeOfCut = TypeOfCut;

    //     setCombos([...combosToPrint])

    //     let indexInCart
    //     let checkOfCartItem = true
    //     let btnAddToCart = e.target


    //     const checkCart = Cart['combos'].length > 0 ?
    //         await Cart['combos'].forEach((item, index) => {
    //             if (item.index === indexOfCombo) {// Si es false significa que el item ya está registrado - y se dentendrá el forEach
    //                 indexInCart = index
    //                 checkOfCartItem = false
    //                 return false
    //             }
    //             return true
    //         }) : true

    //     if (!checkCart) {
    //         setCart({ ...Cart })
    //     }
    // }

    const changeQuantityCombo = (e, id) => {
        let quantity = e.target.value;
        const btnAddToCart = document.getElementById("AddToCart" + id)
        const indexInCart = btnAddToCart.dataset.indexincart

        console.log(indexInCart)
        if (indexInCart === "") {
            return false;
        }
        else {

            if (quantity === "" || quantity === 0) {
                quantity = 1
            }
            else {
                Cart.combos[indexInCart].quantity = quantity
                setCart(prevState => {
                    return {
                        ...prevState,
                        'combos': [
                            ...prevState['combos']
                        ]
                    }
                })
                openNotification(getMessageNotification(combosToPrint[Cart.combos[indexInCart].index].title, quantity, combosToPrint[Cart.combos[indexInCart].index].price), "product_updated")
            }

        }

    }


    return (

        <section className="Form-body-section1-food Form-body-section1-combos" style={{ marginTop: 3 + "vh" }
        }>

            <header title="Mostrar todo" className="Form-body-section1-food-header Form-body-section1-combos-header">

                <p><i>Combos</i></p>

            </header>

            <section className={"Form-body-section1-food-body Form-body-section1-combos-body " + sectionsCollapseStatus.combos} id="section-combos">

                {
                    combosToPrint.length === 0 ? "Cargando..." : //////////////////// Verifica que los combos ya hayan cargado
                        combosToPrint.map((combo, indexOfCombo) =>

                            <div className="" key={combo.id}>
                                <section className={"Form-body-section1-food-body-item Form-body-section1-combos-body-item margin" + indexOfCombo}>

                                    <div className="item-section1">
                                        <p>
                                            <span>


                                                <span className="item-section1-logo">

                                                </span>


                                                <span className="item-section1-title">

                                                    {combo.title}
                                                    <br />
                                                    <span style={{ color: "#00c853", fontSize: "22px" }} className="price">
                                                        <b>{Number(combo.price).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b>
                                                    </span>

                                                </span>

                                            </span>

                                        </p>

                                    </div>


                                    <section className='collapsed'>
                                        {

                                            combo.content.map((item, indexOfItem) => { //////////////////// Imprime los item's del combo actual
                                                return <div className="Form-body-section1-combos-body-item-items">
                                                    <div className="item-section1">
                                                        <p style={{ padding: 0, margin: 0 }}>
                                                            <span style={{ fontWeight: "600", fontSize: "14px" }}> {item.description} </span> -
                                                        <b>
                                                                <span style={{ fontSize: "18px" }}> {` * ${item.quantity} `} </span>
                                                                <span className="unit" style={{ color: "#646464" }}>{" " + item.unit}</span>
                                                            </b></p>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </section>

                                    <section className="item-section2">
                                        <button
                                            id={"btnAddCombo"}
                                            // disabled
                                            type="primary"
                                            className="btnAddProduct disabled"
                                            onClick={(e)=>addComboToCart(e,indexOfCombo,combo.id)}
                                        >

                                            <ShoppingCartOutlined />   Añadir a carrito

                                    </button>
                                    </section>
                                </section>


                            </div>

                        )

                }

            </section>
        </section>
    )
}
