const { default: styled } = require("styled-components");


const UpdateScreen = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100000;
    background: #b9180d;
    width: 100%;
    height: 100vh;

`
const UpdateScreenLogo = styled.section`
    
`

const UpdateScreenMessage = styled.section`
    color: white;
    font-size: 24px;
    text-align: center;
`

const UpdateScreenBtn = styled.section`

`

export {
    UpdateScreen,
    UpdateScreenLogo,
    UpdateScreenMessage,
    UpdateScreenBtn
}