import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";



const store = createStore(combineReducers(rootReducer), composeWithDevTools(applyMiddleware(logger, thunk)))
// const store = createStore(combineReducers(rootReducer))


export default store

