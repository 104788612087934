import React, { useState, useEffect } from 'react';

///////////////////////// Assets

import 'antd/lib/notification/style/css'
import 'antd/lib/message/style/css'
import 'antd/lib/spin/style/css'
import 'antd/lib/input/style/css'
import LogoPig from './../Assets/Iconos del cotizador _Pequeños-Cerdo.svg'
import LogoChicken from './../Assets/Iconos del cotizador _Pequeños-pollo.svg'
import LogoBeef from './../Assets/Iconos del cotizador _Pequeños-Res.svg'
import LogoSausages from './../Assets/Iconos del cotizador _Pequeños-Carnes frias.svg'
import LogoCravings from './../Assets/Iconos del cotizador _Pequeños- Antojos.svg'
import LogoFish from './../Assets/Iconos del cotizador _Pequeños_Pescado.svg'
import Banner from './../Assets/Desktop/Banner-cotizador1080.jpg'
import BannerMb from './../Assets/Mobile/Banner-cotizador-mb.jpg'
import Wtspimg from './../Assets/whatsapp-symbol.svg'
import DivitionImg from './../Assets/divition.png'
import { SearchOutlined } from '@ant-design/icons';
///////////////////////// Assets





//////////////////////// Variables


import { host, dir_api_name } from '../api_vars'
import { Input, notification, Space, Spin } from 'antd';
import { combosPropsVer2 } from './Props/combos';

import { useSelector, useDispatch } from 'react-redux';
import { fetchTotal, fetchCart, fetchNumbsOfItems } from './../Redux/store/storeActions'
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import AniversaryLogo from './../Assets/banner_aniversary.svg'
import PrintCombos from './printCombos';
import { Suspense } from 'react';
import { Button, Collapse, FormControlLabel, Paper, Switch } from '@material-ui/core';

const Categories = React.lazy(() => import('./Home/Categories'));
const FoodSection = React.lazy(() => import('./Home/FoodSection'));

const HeaderContainer = styled.header`
    /* height: 100vh; */
    @media(max-width: 600px){
      padding-top: 55px;
    }
    .search-container{
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    .inpt-search{
      width: 30vw;
      height: 60px;
      border-radius: 4px;
      border: none;
      @media(max-width: 758px){
        width: 70vw;
      }
    }
    .btn-search{
      height: 60px;
      width: 50px;
      text-align: center;
      border: none;
      border-left: 0px;
      background-color: white;
      border-radius: 0px 4px 4px 0px;
    }
    .categories{
      margin: 10rem 0 5rem;
      position: relative;
      background-color: #FFA221;
      padding: 20px 27px 33px;
      border-radius: 4px;
      width: 65vw;
      margin: auto;
      margin-bottom: 8vh;
      z-index: 100;
      @media(max-width: 1400px){
          width: 87vw;
          margin-top: 10vh;
        }
      @media(max-width: 758px){
        margin: 2rem 0 5rem;
        width: 100%;
        padding: 20px 11px 33px;
      }
      h3{
        text-align: center;
        font-size: 32px;
        font-weight: 600;
        color: #ffffff;
      }
      
      &-container{
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: auto;
        margin-block-start: 2rem;
        
        img{
          width: 160px;
        }
        @media(max-width: 600px){
          display: grid;
          grid-template-columns: repeat(3, 1fr);
    row-gap: 13px;
    justify-items: center;
          img{
            width: 26vw;
          }
        }
      }
    }
    .spin_container{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 20vh;
      .ant-spin-dot-item{
        background-color: #b9180d;
      }
    }
    .backBtnPc {
        background-color: white;
        border: 1px solid #f38b01;
        font-family: "Baloo Thambi 2", cursive;
        padding: 5px 15px;
        border-radius: 3px 3px 3px 3px;
        color: #ff9e40;
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
        margin-bottom: 10px;
        transition: all 0.4s;
        width: auto;
        display: flex;
        align-items:center;
        justify-content: space-between;
    }
    .search_result-container{
      margin-top:5vh;
    }
    
    .banner{
      width: 100vw;
      @media(max-width: 758px){
        min-height: 73vh;
      }
      @media(max-width: 350px){
        min-height: 120vh;
      }
      @media(min-width: 360px) and (max-width: 390px){
        min-height: 100vh;
      }
      @media(min-width: 360px) and (max-width: 390px) and (min-height: 800px){
        min-height: 80vh;
      }
      @media(min-width: 1300px){
        min-height: 100vh;
      }
      &-background{
        z-index: 0;
          position: fixed;
          top: 0;
          left: 0;
        img{
          
          height: 100vh;
          width: 100%;
        }
      }
      &-container{
        /* @media(min-width: 768px){
          background-image: url(${Banner});
        }
        @media(max-width: 768px){
          background-image: url(${BannerMb});
        } */
        min-height: 100vh;
        width: 100vw;
        overflow: hidden;
        background-color: #b9180d;
        background-size: cover;
        position: absolute;
        left: 0;
        top: 10vh;
        padding: 2vw;
        display: grid;
        grid-template-columns: 0.5fr 1fr;
        column-gap: 10rem;
        justify-items: center;
        .showmore{
          display: none;
        }
        @media(max-width: 758px){
         grid-template-columns : 1fr;
         padding: 2rem 0;
         .showmore{
            display: block;
            margin: auto;
            background-color: #f38b01;
            color: white;
          }
        }
        &-description{
          font-family: 'Barlow', sans-serif;
          color: white;
          z-index: 1;
          header{
            text-align: center;
            border-bottom: 2px solid #FFA221;
            margin-bottom: 2rem;
            h2{
              font-size: 3.5rem;
              margin: 0;
              transform: rotate(-2deg);
              font-weight: 800;
              color: white;

            }
            h2:first-child{
            }
            h2:nth-child(2n){
              background-color: #FFA221;
              text-shadow: 0px 0px 10px #6f6f6f6e;
              line-height: 3.5rem;
              width: 37vw;
              margin: auto;
            }
            p{
              margin-top: 2rem;
              color: white;
              font-size: 2rem;
              font-weight: 500;
            }
            @media(max-width: 758px){
              margin-top: 2rem;
              grid-row: 1/2;
              border-bottom: 0px solid #FFA221;
              h2{
                font-size: 2rem;
              }
              h2:nth-child(2n){
                width: 90%;
              }
              p{
                font-size: 1.5rem;
              }
            }
          }
          section{
            font-size: 1.5rem;
            min-height: 34vh;
            padding-bottom: 2vh;
            scroll: none;
          }
          @media(max-height: 700px){
            section{
              margin-top: 5vh;
            }
          }
          section::-webkit-scrollbar{
            display: none;
          }
        }
        aside{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          z-index: 1;
          .see-categories{
            color: #FFA221;
            text-align: center;
            margin-top: 2rem;
            font-size: 1.4rem;
            background-color: white;
            border-radius: 4px;
            display: inline-block;
            padding: 0.2rem 2rem;
            box-shadow: 0px 0px 4px rgba(255,255,255,0.4);
            border: 1px solid #FFA221;
            cursor: pointer;
            p{
              margin: 0;
            }
            

          }
        }
      }
    }
    .banner--open{
      height: 134vh;
      @media(min-width: 1400px){
        height: 100vh;
      }
      @media(max-width: 758px){
        height: 150vh;
      }
      @media(min-width: 360px) and (max-width: 390px){
        min-height: 191vh;
      }
      @media(min-width: 360px) and (max-width: 390px) and (min-height: 800px){
        min-height: 100vh;
      }
      @media(max-width: 340px){
        height: 251vh;
      }
    }
`
//////////////////////// Variables



///////////////////////// Vars, states, consts, params propeties and more
/**
 * @var {json} sectionsCollapseStatus - Collapse status of categories | Estado de desplegable de cada categoría
 * @var {number} step - Actually step in shopping  process | Paso actual del proceso de compra
 * @const {string} host - Link of API | Link de la API
 * @prop {array[json]} meatTypeList - List of products this is initiali empty when app load this will to fetch the list with a API | Lista de productos. Esta está inicialmente vacía y cuando la app inicie traerá la lista desde una API
 * @prop {array[json]} combos - List of Combos / Promotions. | Lista de combos / promociones
 * @var { json{ name: array } } Cart - Cart | Carrito de compra
 * @var {json} SubTotals - Sub totals by category | Sub totals por categoría
 * @var {number} total - Total of Cart | Total del carrito de compra
 * @var {json} userData -  Datos del usuario en el formulario - User data in form
 */
///////////////////////// Vars, states, consts, params propeties and more


///////////////////////// Funtions

/**
 * @function changeQuantityItem - Change number of product and add to Cart if is more than 0 with (@function toggleItemCart). Or if is iqual to 0 and is already added in the Cart will be deleted with (@function toggleItemCart)
 * @function toggleItemCart - Add product to cart if is not added. If is already added will to update it or deleted if is igual to 0.
 * @function changeMeatCut - Change the type of meat cut by product
 * @function RegisterUserData - set User data of the form
 * @event {by[Cart]} useEffet - set the Total, set sub totals and Print the cart in the screen when the Cart is update
 * @event {by[]} useEffect - Load list of products when the app is loaded
 * @function register_order - register user order in the database with her user data and Cart content
 * @function collapseAll - collapse all sections
 */

///////////////////////// Funtions


const App = () => {

  /////////////////// START States ///////////////////

  const dispatch = useDispatch()

  notification.config({
    duration: 4,
    bottom: window.screen.width <= 700 ? 17 : 80
  });



  const [sectionsCollapseStatus, setSectionsCollapseStatus] = useState({
    promotions: 'collapsed',
    pig: 'collapsed',
    beef: 'collapsed',
    chicken: 'collapsed',
    fish: 'collapsed',
    sausages: 'collapsed',
    cravings: 'collapsed',
    combos: "collapsed",
    search: "open",
    scroll: 0,
    meatTypeTrigger: "",
    action: ""
  })

  const [step, setStep] = useState(0) /* Paso actual en el proceso de compra - Actually step in the shopping process. */

  const [meatTypeList, setMeetTypeList] = useState({
    promotions: [],
    pig: [],
    beef: [],
    chicken: [],
    fish: [],
    sausages: [],
    cravings: [],
    search: []
  }) // Stock de la tienda - Stock of the store

  const [combos, setCombos] = useState(
    combosPropsVer2
  ) // Combos
  const [details, setDetails] = useState(window.outerWidth > 1300)
  const CartRedux = useSelector(state => state.store.cart)

  const totalRedux = useSelector(state => state.store.total)

  const PresentationsList = [
    { "name": "Porcionado 5xLb", "selectable": true },
    { "name": "Porcionado 4xLb", "selectable": true },
    { "name": "Porcionado 3xLb", "selectable": true },
    { "name": "Porcionado 2xLb", "selectable": true },
    { "name": "Entero", "selectable": true },
    { "name": "Molido", "selectable": true },
    { "name": "Mariposa", "selectable": true },
    { "name": "Goulash", "selectable": true },
    { "name": "Medallones", "selectable": true }
  ]

  const [Cart, setCart] = useState(CartRedux) //Carrito de compras - Cart

  const [SubTotals, setSubTotals] = useState({
    promotions: 0,
    pig: 0,
    beef: 0,
    chicken: 0,
    fish: 0,
    sausages: 0,
    cravings: 0,
    combos: 0
  }) // Sub totales de cada sección - Sub totals by category

  const [total, setTotal] = useState(totalRedux) // Total






  const [searchShow, setSearchShow] = useState("off")

  const { search } = useParams()

  const [searchParms, setSearchParms] = useState(search)




  /////////////////// END States ///////////////////



  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



  /////////////////// START Functions ///////////////////

  const getMessageNotification = (title, quantity, price) => {

    let initalPrice = price / quantity

    return title + " x " + quantity + " | " + Number(initalPrice * quantity).toLocaleString("de-AT", { style: "currency", currency: "USD" })
  }

  const openNotification = (title, type) => {
    let message
    const notificationSucess = (message) => {
      notification.success({
        message: message,
        description: title,
        onClick: () => {
          console.log('Notification Clicked!');
        },
        placement: "bottomRight",
        className: "notifications"
      });
    }

    const notificationDeleted = (message) => {
      notification.error({
        message: message,
        description: title,
        onClick: () => {
          console.log('Notification Clicked!');
        },
        placement: "bottomRight",
        className: "notifications"
      })
    }



    switch (type) {
      case "product_added":
        message = "Añadido al carrito!"
        notificationSucess(message)
        break;

      case "product_deleted":
        message = "Eliminado!"
        notificationDeleted(message)
        break;

      case "product_updated":
        message = "Actualizado!"
        notificationSucess(message)
        break;

      case "error_reload":
        message = "Error al cargar!"
        notificationDeleted(message)
        break;

      case "error_finish":
        message = "Error al cargar"
        notificationDeleted(message)
        break;

      case "consult_date":

        message = "Verificando disponibilidad"
        notificationSucess(message)
        break;

      case "date_unavailable":

        message = "No disponible"
        notificationDeleted(message)
        break;
      case "date_available":

        message = "Disponible"
        notificationSucess(message)
        break;

      default:
        return false;
    }


  };


  function changeQuantityItem(id, meatType, name, initialPrice, unit, index, presentation, offer, offer_price, meat_line) {

    let quantity = document.getElementById("Quantity-" + id).value


    if (quantity.indexOf(".") !== -1) {
      alert("No se permiten '.', ni ','")
      quantity = quantity.replace(/[^0-9]/g, "").slice(-1)
      document.getElementById("Quantity-" + id).value = quantity.replace(/[^0-9]/g, "").slice(0, 1)

    }

    const finalPrice = Number(initialPrice) * Number(quantity)

    const final_offer_price = Number(offer_price) * Number(quantity)


    if (quantity < 0) quantity = 0


    let TypeOfCut = document.querySelector(`#TypeOfCutBox-${id} input`).value

    // totalInput.value = Intl.NumberFormat("es-CO", { style: "currency", currency: "COP" }).format(totalPrice)

    if (quantity > 0 || quantity !== "") {

      setCart(prevState => {
        return {
          ...prevState, [`${meatType}`]: [...prevState[meatType],
          { name: name, quantity: Number(quantity), price: offer === "1" ? final_offer_price : finalPrice, initial_price: finalPrice, offer: offer, TypeOfCut: TypeOfCut, TypesOfCut: presentation, unit: unit, meat_line: meat_line }]
        }
      })

      openNotification(getMessageNotification(name, quantity, finalPrice), "product_added")

    }

  }


  const changeMeatCut = (e) => {

    e.target.className = ""

  }

  const collapseAll = () => {

    setSectionsCollapseStatus({
      pig: 'collapsed',
      beef: 'collapsed',
      chicken: 'collapsed',
      fish: 'collapsed',
      sausages: 'collapsed',
      cravings: 'collapsed',
      combos: 'collapsed'
    })

    window.scrollTo(0, window.screen.height)

    document.querySelectorAll(".itemsForm").forEach(item => {
      item.value = ""
    })

  }


  const setStepToTwo = () => {

    window.scrollTo(0, 0);

    if (window.innerWidth < 700)

      document.getElementById("linkToSummary").click()

    else

      document.getElementById("linkToCart").click()

  }

  const checkStep = () => {

    if (step === 0 && total > 0) {


      setStepToTwo()

      return false
    }

    // else if (step === 1 && total > 0 && checkPrice()) {

    //   setStepToTwo()

    //   return false

    // }

    else if (step === 2 && total > 0) {

      // register_order()

      return false

    }

    else { alert("Por favor seleccione al menos un producto"); }

  }

  const updatePriceResume = () => {
    let summaryPig = 0
    let summaryBeef = 0
    let summaryChicken = 0
    let summaryFish = 0
    let summarySausages = 0
    let summaryCravings = 0
    let summaryCombos = 0
    let summaryPromotions = 0
    let summarySearch = 0

    let numbOfItemsInCart = 0

    Cart.promotions.forEach(item => {
      summaryPromotions += Number(item.price)
      numbOfItemsInCart += 1
    })

    Cart.pig.forEach(item => {
      summaryPig += Number(item.price)
      numbOfItemsInCart += 1
    })
    Cart.beef.forEach(item => {
      summaryBeef += Number(item.price)
      numbOfItemsInCart += 1

    })
    Cart.chicken.forEach(item => {
      summaryChicken += Number(item.price)
      numbOfItemsInCart += 1

    })
    Cart.fish.forEach(item => {
      summaryFish += Number(item.price)
      numbOfItemsInCart += 1

    })
    Cart.sausages.forEach(item => {
      summarySausages += Number(item.price)
      numbOfItemsInCart += 1

    })
    Cart.cravings.forEach(item => {
      summaryCravings += Number(item.price)
      numbOfItemsInCart += 1

    })
    Cart.combos.forEach(item => {
      summaryCombos += item.price
      numbOfItemsInCart += 1
    })

    Cart.search.forEach(item => {
      summarySearch += Number(item.price)
      numbOfItemsInCart += 1

    })
    setSubTotals(
      {
        ...SubTotals,
        promotions: summaryPromotions,
        pig: summaryPig,
        beef: summaryBeef,
        chicken: summaryChicken,
        fish: summaryFish,
        sausages: summarySausages,
        cravings: summaryCravings,
        combos: summaryCombos
      }
    )
    let total = summaryPig + summaryBeef + summaryChicken + summaryFish + summarySausages + summaryCravings + summaryCombos + summaryPromotions + summarySearch

    setTotal(total)

    dispatch(fetchTotal(total))

    dispatch(fetchNumbsOfItems(numbOfItemsInCart))

  }

  const searchProducts = () => {

    setSearchShow("loading")

    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Origin', host);
    const miInit = {
      method: 'POST',
      headers: headers,
      body: `search=${searchParms}`,
      mode: 'cors'
    };

    if (searchParms !== "")


      fetch(host + dir_api_name + `/search_product.php`, miInit)

        .then((res) => {

          if (res.ok)

            return res.json()

          else

            throw res

        })
        .then((res) => {

          console.log(res)

          setMeetTypeList({ ...meatTypeList, search: res })

          setSearchShow("loaded")

        })
        .catch((err) => alert(err))
  }

  const RegisterWhtsappClick = () => {

    const headers = new Headers();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Origin', host);
    const miInit = {
      method: 'GET',
      headers: headers,
    };

    fetch(host + dir_api_name + `/stats.php`, miInit)

        .then((res) => {

          if (res.ok)

            return res.text()

          else

            throw res

        })
        .then((res) => {

        })
        .catch((err) => console.log(err))
  }

  /////////////////// END Functions ///////////////////



  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



  /////////////////// START Effescts ///////////////////


  useEffect(() => {

    updatePriceResume()
    dispatch(fetchCart(Cart))

  }, [Cart]) // Use state by cart

  useEffect(() => {

    updatePriceResume()

  }, [])

  useEffect(() => {

    document.title = "Santas Carnitas | Tienda"

  }, [])

  useEffect(() => {

    if (searchParms !== "" && searchParms !== undefined) {
      document.title = "Santas Carnitas | Resultado " + searchParms
      searchProducts()
    }
    else document.title = "Santas Carnitas | Tienda"

  }, [])


  useEffect(() => {

    let BannerImage = ""
    if (window.outerWidth < 600) {
      BannerImage = BannerMb
    } else {
      BannerImage = Banner
    }

    let BannerBackground = new Image(100 + "vw", 100 + "vh")

    BannerBackground.src = BannerImage
    BannerBackground.addEventListener("load", () => {
      console.info("Image")
      console.info(BannerBackground)
      document.querySelector(".banner-background").appendChild(BannerBackground)
    })

    // document.querySelector(".banner-container").style.backgroundImage = 'url("' + BannerImage + '")'



  }, [])


  /////////////////// END Effects ///////////////////



  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



  /////////////////// START Component Content ///////////////////

  ////////////////// Styles ////////////////////////////////////

  ////////////////// END Styles ////////////////////////////////////

  return (

    <div className="Form">
      <Link to="/carrito/" id="linkToCart" style={{ display: "none" }}></Link>
      <Link to="/resumen/" id="linkToSummary" style={{ display: "none" }}></Link>
      <img src={AniversaryLogo} alt="" className="logo_aniversary" style={{ width: "100%" }} />

      <HeaderContainer>

        <section className={details ? "banner banner--open" : "banner"}>
          <section className="banner-container">
            <div className="banner-background"></div>
            <aside>
              <Input.Group className="search-container">
                <Input placeholder="Buscar" className="inpt-search" value={searchParms}
                  onChange={(e) => { setSearchParms(e.target.value); }}
                  onKeyPress={(e) => searchParms !== undefined && searchParms !== "" ? e.key === "Enter" ? window.location.href = "/busqueda/" + searchParms : false : false}>
                </Input>
                <Button color="default" variant="contained" endIcon={<SearchOutlined />} className="btn-search" onClick={() => searchParms !== undefined && searchParms !== "" ? window.location.href = "/busqueda/" + searchParms : false}></Button>
              </Input.Group>
              <a href="#categories">
                <div className="see-categories">
                  <p>Ver categorías</p>
                </div>
              </a>
            </aside>


            <article className="banner-container-description">
              <header>
                <h2>BIENVENIDOS A</h2>
                <h2>NUESTRO PORTAL DE <br /> COMPRAS VIRTUAL</h2>
                <p>Un espacio especializado para la venta <br /> y domicilio de carnes a tu gusto.</p>
                <Button
                  className="showmore"
                  color="default"
                  variant="contained"
                  onClick={()=> setDetails(!details)}
                >{ !details ? "Saber más" : "Ocultar" }</Button>
              </header>

              <section>

                <Collapse in={details}>
                  <Paper elevation={4} style={{ background: "transparent", boxShadow: "none", color: "white" }} >
                    <p>Gracias a esta herramienta podrás solicitar tu pedido a domicilio de forma fácil, rápida. También disfrutarás los siguientes beneficios:</p>
                    <ul>
                      <li>Programa tu pedido para el mismo día o para el que quieras, según disponibilidad.</li>
                      <li>Guarda tu historial de compras y pide la misma lista cada vez que lo necesites.</li>
                      <li>Elige como quieres que te lleguen tus carnes: (Entera, porcionada, molida, y muchas más opciones)</li>
                      <li id="categories">Entérate de las promociones vigentes</li>
                    </ul>
                  </Paper>
                </Collapse>

              </section>
            </article>
          </section>
        </section>


        {
          searchShow === "off" ?
            <Suspense fallback={<div aria-label="Categorías" className="categories"></div>}>
              <Categories />
            </Suspense>
            : searchShow === "loading" ?
              <section className="spin_container">
                <Spin size="large" />
              </section> :
              <section className="search_result-container">
                <button className="backBtnPc" onClick={() => { setSearchShow("off"); window.location.href = "/" }}>Volver a tienda</button>
                <Suspense fallback={<div className="foodSections">Loading...</div>}>
                  <FoodSection sectionsCollapseStatus={
                    sectionsCollapseStatus
                  }
                    setSectionsCollapseStatus={setSectionsCollapseStatus}
                    meatTypeList={meatTypeList.search}
                    meatType={"search"}
                    changeQuantityItem={changeQuantityItem}
                    changeMeatCut={changeMeatCut}
                    SubTotals={SubTotals}
                    LogoSection={<span role="img" aria-label="Favoritos"></span>}
                    PresentationsList={PresentationsList}
                    title={"Resultado"}
                    marginTop={0}
                  />
                </Suspense>
              </section>
        }

      </HeaderContainer>

      <section className="Form-body">

        <div id="stage-0" data-stage="0" className="Form-body-section1" style={{ display: step === 0 || step === 1 ? "block" : "none" }}>


          <Space size="large" direction="vertical" >

            <Suspense fallback={<div className="foodSections">Loading...</div>}>
              <FoodSection sectionsCollapseStatus={
                sectionsCollapseStatus
              }
                setSectionsCollapseStatus={setSectionsCollapseStatus}
                meatTypeList={meatTypeList.promotions}
                meatType={"promotions"}
                changeQuantityItem={changeQuantityItem}
                changeMeatCut={changeMeatCut}
                SubTotals={SubTotals}
                LogoSection={<span role="img" aria-label="Favoritos">⭐</span>}
                PresentationsList={PresentationsList}
                title={"Promociones"}
                name_in_db={"promotions"}
                marginTop={0}
              />
            </Suspense>

            <img src={DivitionImg} alt="" width="0%" id="combos" />

            <PrintCombos sectionsCollapseStatus={sectionsCollapseStatus}

              setSectionsCollapseStatus={setSectionsCollapseStatus}
              combosToPrint={combos}
              setCombos={setCombos}
              changeQuantityItem={changeQuantityItem}
              changeMeatCut={changeMeatCut}
              SubTotals={SubTotals}
              Cart={Cart}
              setCart={setCart}
              openNotification={openNotification}
            />

            <img src={DivitionImg} alt="" width="0%" id="pig" />

            <Suspense fallback={<div className="foodSections" >Loading...</div>}>

              <FoodSection sectionsCollapseStatus={
                sectionsCollapseStatus
              }
                setSectionsCollapseStatus={setSectionsCollapseStatus}
                meatTypeList={meatTypeList.pig}
                meatType={"pig"}
                changeQuantityItem={changeQuantityItem}
                changeMeatCut={changeMeatCut}
                SubTotals={SubTotals}
                LogoSection={<img src={LogoPig} alt="Cerdo" />}
                PresentationsList={PresentationsList}
                title={"Cerdo"}
                marginTop={3}
                name_in_db={"cerdo"}
              />
            </Suspense>
            {/*  Fin section Pig */}
            <img src={DivitionImg} alt="" width="0%" id="beef" />
            <Suspense fallback={<div className="foodSections" >Loading...</div>}>

              <FoodSection sectionsCollapseStatus={
                sectionsCollapseStatus
              }
                setSectionsCollapseStatus={setSectionsCollapseStatus}
                meatTypeList={meatTypeList.beef}
                meatType={"beef"}
                name_in_db={"res"}
                changeQuantityItem={changeQuantityItem}
                changeMeatCut={changeMeatCut}
                SubTotals={SubTotals}
                LogoSection={<img src={LogoBeef} alt="Res" />}
                PresentationsList={PresentationsList}
                title={"Res"}
                marginTop={3}
              />
            </Suspense>
            {/* Fin section beef */}
            <img src={DivitionImg} alt="" width="0%" id="chicken" />
            <Suspense fallback={<div className="foodSections" >Loading...</div>}>

              <FoodSection sectionsCollapseStatus={
                sectionsCollapseStatus
              }
                setSectionsCollapseStatus={setSectionsCollapseStatus}
                meatTypeList={meatTypeList.chicken}
                meatType={"chicken"}
                name_in_db={"pollo"}
                changeQuantityItem={changeQuantityItem}
                changeMeatCut={changeMeatCut}
                SubTotals={SubTotals}
                LogoSection={<img src={LogoChicken} alt="Pollo" />}
                PresentationsList={PresentationsList}
                title={"Pollo"}
                marginTop={3}
              />
            </Suspense>
            {/* Fin section chicken */}
            <img src={DivitionImg} alt="" width="0%" id="fish" />
            <Suspense fallback={<div className="foodSections" >Loading...</div>}>

              <FoodSection sectionsCollapseStatus={
                sectionsCollapseStatus
              }
                setSectionsCollapseStatus={setSectionsCollapseStatus}
                meatTypeList={meatTypeList.fish}
                meatType={"fish"}
                name_in_db={"PESCADOS Y MARISCOS"}
                changeQuantityItem={changeQuantityItem}
                changeMeatCut={changeMeatCut}
                SubTotals={SubTotals}
                LogoSection={<img src={LogoFish} alt="Pescado" />}
                PresentationsList={PresentationsList}
                title={"Pescados Y Mariscos"}
                marginTop={3}
              />
            </Suspense>
            {/*  Fin section Fish */}

            <img src={DivitionImg} alt="" width="0%" id="sausages" />
            <Suspense fallback={<div className="foodSections" >Loading...</div>}>

              <FoodSection sectionsCollapseStatus={
                sectionsCollapseStatus
              }
                setSectionsCollapseStatus={setSectionsCollapseStatus}
                meatTypeList={meatTypeList.sausages}
                meatType={"sausages"}
                name_in_db={"CARNES FRIAS"}
                changeQuantityItem={changeQuantityItem}
                changeMeatCut={changeMeatCut}
                SubTotals={SubTotals}
                LogoSection={<img src={LogoSausages} alt="Carnes Frías" />}
                PresentationsList={PresentationsList}
                title={"Carnes Frias"}
                marginTop={3}
              />
            </Suspense>
            {/*  Fin section Sausuges */}
            <img src={DivitionImg} alt="" width="0%" id="cravings" />
            <Suspense fallback={<div className="foodSections" >Loading...</div>}>

              <FoodSection sectionsCollapseStatus={
                sectionsCollapseStatus
              }
                setSectionsCollapseStatus={setSectionsCollapseStatus}
                meatTypeList={meatTypeList.cravings}
                meatType={"cravings"}
                name_in_db={"ANTOJOS"}
                changeQuantityItem={changeQuantityItem}
                changeMeatCut={changeMeatCut}
                SubTotals={SubTotals}
                LogoSection={<img src={LogoCravings} alt="Antojos" />}
                PresentationsList={PresentationsList}
                title={"Antojos"}
                marginTop={3}
              />
            </Suspense>
            {/*  Fin section Craving */}

          </Space>

        </div>

      </section>


      <section className="StepButton" id={step < 2 ? "StepButtonStage0" : ""}>

        <button className="Back" onClick={() => { setStep(0); collapseAll() }} style={{ display: step === 2 ? "block" : "none" }}>
          Volver
        </button>

        <button onClick={() => checkStep()} style={{ boxShadow: step === 2 ? "-1.5px 0px 3px rgba(143, 62, 0, 0.5)" : "none" }}>

          <Button style={{ margin: 0, }}>Mostrar Carrito <span style={{ color: "#fafafa" }}> | </span>
            <span style={{ color: "white" }}>Total : {<b>{Number(total).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b>}</span>
          </Button>

        </button>

      </section>

      <section className="wtsp">
        <a href="https://api.whatsapp.com/send?phone=5742041077&text=Hola" target="_blanck" onClick={()=> RegisterWhtsappClick()}>
          <img src={Wtspimg} alt="Whatsapp imagen" title="Contáctanos" />
        </a>
      </section>
    </div>
  );
}

export default App;
