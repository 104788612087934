import {
    SET_USER
} from "./userTypes";


let initialState = {
    session: {
        username: "",
        token: "",
        email: "",
        userData: {
            "name": "",
            "last_name": "",
            "id": "",
            "cell": "",
            "tel": "",
            addresses: [
                {
                    "address": "",
                    "orientation": "",
                    "city": "",
                    "neighborhood": "",
                    "commune": "",
                    "idDirection": "",
                    "addressID": "",
                    "point_reference": "",

                }
            ],
            addressSelected: 0,
            "date": "",
            "hour": "",
            "minute": "",
            "am_pm": "",
            "payment_method": ""
        },
        notifications: 0,
        emailStatus: true,
        logged: false,
        forgottenCart: false,
        orders: {
            page: 1
        }
    }
}



const userReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                session: {
                    ...state.session,
                    ...action.payload
                }
            }

        default:
            return state
    }
}

export default userReducer