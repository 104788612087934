const { default: styled } = require("styled-components");


const Body = styled.section`
    background-color: #F5F5F5;
    min-height: 80vh;
    padding: 5vh 2vw;
    padding-top: 9vh;
`

export {
    Body
}