import {
    SET_CART,
    SET_COMBOS,
    SET_NUMB_OF_ITEMS,
    SET_TOTAL,
    SET_USER_DATA
} from "./storeTypes";

import {
    combosPropsVer2
} from "../../Components/Props/combos";

const cart = JSON.parse(localStorage.getItem("cart")) !== null && JSON.parse(localStorage.getItem("cart")).promotions !== undefined && JSON.parse(localStorage.getItem("cart")).search !== undefined ? {
    ...JSON.parse(localStorage.getItem("cart")),
    delivery_price: 0
} : {
    promotions:[],
    pig: [],
    beef: [],
    chicken: [],
    fish: [],
    sausages: [],
    cravings: [],
    combos: [],
    search: [],
    delivery_price: 0,
    discount: 0
}

let initialState = {
    cart: {
        ...cart
    },
    userData: {
        username: "",
        name: "",
        last_name: "",
        id: "",
        email: '',
        cell: "",
        tel: "",
        address: "",
        orientation: "",
        city: "",
        neighborhood: "",
        commune: "",
        idDirection: "",
        addressID: "",
        point_reference: "",
        date: "",
        hour: "",
        minute: "",
        am_pm: "",
        payment_method: "",
        dispatch_option: ""
    },
    combos: combosPropsVer2,
    numbsOfItems: 0,
    total: 0
}



const storeReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_CART:
            let newState = {
                ...state,
                cart: {
                    ...state.cart,
                    ...action.payload
                }
            }

            localStorage.setItem("cart", JSON.stringify(newState.cart))

            return newState

        case SET_COMBOS:
            return {
                ...state,
                combos: {
                    ...action.payload
                }
            }

        case SET_TOTAL:
            return {
                ...state,
                total: action.payload
            }
        case SET_USER_DATA:
            return {
                ...state,
                userData: {
                    ...state.userData,
                    ...action.payload
                }
            }
        case SET_NUMB_OF_ITEMS:
            return {
                ...state,
                numbsOfItems: action.payload
            }
        default:
            return state
    }
}

export default storeReducer